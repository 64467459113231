/*
 * 업무구분 : 업무공통
 * 화면 명  : MSPBC315315P
 * 화면 설명: 지문촬영 PUSH 시 노출시키는 화면
 */
<template>
  <ur-page-container title="New Page" class="msp" :show-title="false">
    <ur-box-container alignV="start" class="ns-fingerprint-area">
      <ur-box-container alignV="start" componentid="ur_box_container_10" direction="column" class="ns-fingerprint-area">

        <!-- 지문인증 성공 시작 -->
        <ur-box-container alignV="start" componentid="ur_box_container_01" direction="column" class="ns-fingerprint-box" v-if="lv_PageType === '1'">
          <div class="fingerprint-txt">
            <img src="/images/icon_finger_complete.svg" class="icon-box">
            <div class="txt-point-blue">
              지문 촬영이 완료되었습니다.<br>아래 확인 버튼을 눌러주세요
            </div> 
            <div class="txt-sub">
              <div>
                지문정보는 금융결제원과 삼성생명이<br>분산 보관하여 안전하게 관리됩니다.
              </div>
            </div>
          </div>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_02" direction="column" class="-pub-dim" v-if="lv_IsShowLoading">
          <span class="-pub-loading">
            <br>
            <span class="-pub-text" v-if="lv_IsShowLoading && lv_LoadingText0">고객님의 지문정보로 금융결제원에 등록과 인증이 진행중입니다.<br/>진행에는 약 5초 가량 소요될 수 있습니다.<br/>잠시만 기다려주세요.</span>
            <span class="-pub-text" v-if="lv_IsShowLoading && lv_LoadingText1">고객님의 지문정보를 등록하고 있습니다.<br/>잠시만 기다려주세요.</span>
            <span class="-pub-text" v-if="lv_IsShowLoading && lv_LoadingText2">등록된 지문정보를 인증하고 있습니다.<br/>잠시만 기다려주세요.</span>
            <span class="-pub-text" v-if="lv_IsShowLoading && lv_LoadingText3">금융결제원에 등록과 인증을 진행중입니다.<br/>잠시만 기다려주세요.</span>
          </span>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_03" direction="column" class="ns-btn-relative" v-if="lv_PageType === '1'">
          <div class="relative-div">
            <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseProcess">취소</mo-button>
            <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_FingerPrintRegister">확인</mo-button>
          </div>
        </ur-box-container>
        <!-- 지문인증 성공 끝 -->
          <!-- <div style="position: absolute;
          top: 80px;
          width: 190px;
          border: 1px solid red;
          padding: 8px 0px 8px 8px;">
          test:
          <mo-radio v-model="lv_PageType" value="1" style="width: 70px; margin-left: 0px;">1</mo-radio>
          <mo-radio v-model="lv_PageType" value="2" style="width: 70px; margin-left: 0px;">2</mo-radio>
          <mo-radio v-model="lv_PageType" value="3" style="width: 70px; margin-left: 0px;">3</mo-radio>
          <mo-radio v-model="lv_PageType" value="4" style="width: 70px; margin-left: 0px;">4</mo-radio>
        </div> -->
        <!-- 지문인증 실패 시작 -->
        <div class="ns-fingerprint-box" v-if="lv_PageType === '2' || lv_PageType === '3' || lv_PageType === '4'">
          <div class="fingerprint-txt">
            <img src="/images/icon_finger_fail.svg" class="icon-box">
            <div class="txt-point-red">
              지문 촬영이 실패하였습니다.
            </div> 
            <div class="txt-sub">
              <div v-if="lv_PageType === '2'">주의사항 확인 후 다시 촬영을 진행해 주세요.</div>
              <div v-if="lv_PageType==='3' && lv_RouteParams.svcTp==='1'">아래 ‘다시 시도’ 버튼을 눌러 재요청해주세요.<br>지속적인 실패 시, 피보험자의 서면동의서 작성 후<br>지점접수 진행 바랍니다.<br>(서면동의서 촬영은 생략됩니다.)</div>
              <div v-if="lv_PageType==='3' && lv_RouteParams.svcTp==='2'">아래 ‘다시 시도’ 버튼을 눌러 재요청해주세요.<br>‘확인’ 선택 시 지문등록이 종료됩니다.</div>
              <div v-if="lv_PageType==='3' && lv_RouteParams.svcTp==='3'">아래 ‘다시 시도’ 버튼을 눌러 재요청해주세요.<br>‘확인’ 선택 시 지문검증이 종료됩니다.</div>
              <div v-if="lv_PageType==='4' && lv_RouteParams.svcTp==='1'">주의사항 확인 후 재촬영을 진행하시거나<br>피보험자의 서면동의서 작성 후<br>지점접수 진행 바랍니다.<br>(서면동의서 촬영은 생략됩니다.)</div>
              <div v-if="lv_PageType==='4' && (lv_RouteParams.svcTp==='2' || lv_RouteParams.svcTp==='3')">주의사항 확인 후 재촬영을 진행하시거나<br>‘확인’ 버튼을 눌러 종료하시기 바랍니다.</div>
            </div>
          </div>
        </div>

        <ur-box-container alignV="start" componentid="ur_box_container_06" direction="column" class="ns-btn-relative" v-if="lv_PageType === '2'">
          <div class="relative-div">
            <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseProcess">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue btn-one" @click="fn_ReopenCaptureFingerPrint">다시촬영</mo-button>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_06" direction="column" class="ns-btn-relative" v-if="lv_PageType === '3'">
          <div class="relative-div">
            <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseProcess">확인</mo-button>
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_FingerPrintRegister">다시시도</mo-button>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_06" direction="column" class="ns-btn-relative" v-if="lv_PageType === '4'">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ReopenCaptureFingerPrint">다시촬영</mo-button>
            <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseProcess">확인</mo-button>
          </div>
        </ur-box-container>


        <ur-box-container alignV="start" componentid="ur_box_container_07" direction="column" v-model="lv_ShowAlert">
          <mo-bottom-sheet ref="bs1" class="ns-bottom-sheet">
            <div class="cancel-ment fs17 txt-center">{{this.lv_MsgAlert1}}</div>
            <template v-slot:action>
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
                <div class="relative-div">
                  <mo-button componentid="mo_button_005" shape="border" size="medium" class="ns-btn-round white" @click="close">아니요</mo-button>
                  <mo-button componentid="mo_button_006" shape="border" size="medium" class="ns-btn-round blue" @click="fn_BackBtnClicked">예</mo-button>
                </div>
              </ur-box-container>
            </template>
          </mo-bottom-sheet>
        </ur-box-container>
        <!-- 지문인증 실패 끝 -->
        
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC315P",
    screenId: "MSPBC315P",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
      this.fn_Init()
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPBC315P')
    },
    beforeDestroy () {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      content:'Snackbar text',
      lv_Timer: {}, // 로딩 텍스트 롤링 시 사용
      lv_Rolling: true, // 로딩 텍스트 롤링 여부
      lv_IsProd: !!(process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2' || process.env.NODE_ENV === 'production'), // 실행환경이 운영인지 체크 - local/development/stage/production
      lv_RouteParams: {}, // push로 받은 데이타
      lv_ShowAlert: false, // 메시지 팝업 Show 여부
      lv_ShowAlert1: false, // 지점처리 메시지 팝업 Show 여부
      lv_MsgAlert1: '', // 지점처리 메시지 내용
      lv_PageType: '0', // 0:지문촬영하러 접근했을때, 1:지문촬영성공(확인), 2:지문촬영실패(다시촬영), 3:지문등록및인증실패(지점처리/다시시도), 4:지문촬영실패(다시촬영/지점처리)
      lv_BaseData: {}, // 기초정보 데이타
      lv_ResultBioData: [], // 지문촬영앱에서 리턴되는 데이타
      lv_CaptureFingerPrintData: {}, // 지문촬영앱 실행시 Input 데이타
      lv_CaptureFingerPrintFailedCnt: 0, // 지문촬영실패건수
      lv_IsShowLoading: false, // 로딩
      lv_LoadingText0: false, // 로딩 텍스트 0
      lv_LoadingText1: false, // 로딩 텍스트 1
      lv_LoadingText2: false, // 로딩 텍스트 2
      lv_LoadingText3: false, // 로딩 텍스트 3
      lv_TempText: '지문 촬영이 실패하였습니다.',
      lv_TempText1: '지문 촬영이 연속 실패하였습니다.',
      lv_AlertPop: {}
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
    /*********************************************************
     * Function명: fn_Init
     * 설명: 최초접근 초기화 프로세스
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_Init () {
      // push or route에서 받은 데이타
      // push 데이터 구조 - 91|svcTp=1,svcId=79746031840110700202
      this.lv_RouteParams = this.$route.params || {}
      // Test - Start
      if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
      // this.lv_RouteParams.svcTp = '1' // 전자서명
      // this.lv_RouteParams.svcId = '11736085550171600202' // 영수증아이디
      // this.lv_RouteParams.svcId = '11736085550171600202' // 영수증아이디
      this.lv_RouteParams.svcTp = '2' // 지문등록
      this.lv_RouteParams.svcId = '99137394117171019102' // 채널고객아이디
      // this.lv_RouteParams.svcTp = '3' // 전자서명 검증
      // this.lv_RouteParams.svcId = '11736085550171600202' // 영수증아이디
      }
      // Test - End
      if (this.lv_RouteParams.svcTp) {
        let lv_Vm = this
        let trnstId = this.lv_RouteParams.svcTp === '0' ? 'txTSSPS40S3' : 'txTSSPS40S1' // txTSSPS40S3 -> 지문촬영연습모드, txTSSPS40S1 -> 전자서명 사전정보 조회 서비스
        let pParams = {}
        pParams.bioSvcTp = this.lv_RouteParams.svcTp // 서비스 구분 (0:테스트, 1:전자서명, 2:지문등록, 3:전자서명검증)
        pParams.bioSvcId = this.lv_RouteParams.svcId // svcTp에 따른 구분아이디  (영수증아이디, 고객아이디)
        console.log('지문촬영 진입 시 fn_Init()', pParams)
        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (res) {
            let resData = res.body || {}
            if (Object.keys(resData).length > 0) {
              lv_Vm.lv_BaseData = resData
              lv_Vm.fn_OpenCaptureFingerPrint() // 지문촬영앱 실행
            } else {
              lv_Vm.fn_BottomAlert(res.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      } else {
        this.fn_BottomAlert('앱 알림에 포함된 데이타가 없습니다.')
        this.fn_BackBtnClicked()
      }
    },
    /*********************************************************
     * Function명: fn_OpenCaptureFingerPrint
     * 설명: 지문촬영앱 실행
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OpenCaptureFingerPrint () {
      let lv_Vm = this
      lv_Vm.getStore('progress').dispatch('UPDATE', true) // 프로그래스바 표시
      if (Object.keys(lv_Vm.lv_CaptureFingerPrintData).length < 1) {
        // 최초촬영시 데이타 셋팅
        let args = {}
        let tmpStr = ''
        let tmpObj = {}
        let tmpObj1 = {}
        let gender = ''
        let age = ''
        if (lv_Vm.lv_RouteParams.svcTp !== '0') { // 촬영연습모드가 아니면
          // 성별
          tmpStr = lv_Vm.lv_BaseData.custInfo.knb.slice(6, 7)
          if (tmpStr === '1' || tmpStr === '3' || tmpStr === '5' || tmpStr === '7') {
            gender = 'male'
          } else if (tmpStr === '2' || tmpStr === '4' || tmpStr === '6' || tmpStr === '8') {
            gender = 'female'
          } else {
            gender = ''
          }
          // 나이
          if (tmpStr === '1' || tmpStr === '2' || tmpStr === '5' || tmpStr === '6') {
            age = '' + ((new Date().getFullYear() - Number('19' + lv_Vm.lv_BaseData.custInfo.knb.slice(0, 2))) + 1)
          } else if (tmpStr === '3' || tmpStr === '4' || tmpStr === '7' || tmpStr === '8') {
            age = '' + ((new Date().getFullYear() - Number('20' + lv_Vm.lv_BaseData.custInfo.knb.slice(0, 2))) + 1)
          } else {
            age = ''
          }
        }
        args.serialNo = lv_Vm.lv_BaseData.bioLicenseKey // 솔루션 시리얼 번호
        args.publicKey = lv_Vm.lv_BaseData.bioPublicKey // 서버 공개키
        args.publicKeyVer = lv_Vm.lv_BaseData.bioPublicKeyVersion // 서버 공개키 버전
        args.authCode = lv_Vm.lv_BaseData.bioScCd // 생체인증구분코드(지문===001)
        args.siteId = lv_Vm.lv_BaseData.bioComyCd // 지문인식기 인식업체코드금결원코드 (위닝아이=00204)
        args.captureOptions = []
        // 등록 파라메타
        tmpObj.captureMode = 'enroll' // 등록
        // tmpObj.repeatCnt = (this.lv_RouteParams.svcTp === '1') ? '1' : (this.lv_RouteParams.svcTp === '2') ? '0' : '1' // 반복횟수
        tmpObj.repeatCnt = '1' // 반복횟수
        tmpObj.serviceCode = '등록' // 등록/인증/서명/일괄
        tmpObj.serviceId = lv_Vm.lv_RouteParams.svcId // 영수증ID or 고객ID
        tmpObj.userType = '피보험자' // 피보험자/계약자/수익자/보호자/기타
        tmpObj.systemMessage = lv_Vm.lv_BaseData.custInfoCntnt // ex) 촬영 대상자 : 홍길동<br>최근 서명된 지문 : 2020년 05월 27일 왼손엄지
        tmpObj.gender = gender // 성별
        tmpObj.age = age // 나이
        tmpObj.fingerMode = lv_Vm.lv_BaseData.bioDefaultFinger // 손가락 번호 - 오른손엄지('1')~약지('5'), 왼손엄지('6')~약지('10')
        tmpObj.useEnrolledPrivateInfo = 'true' // 연속촬영시 성별,나이,손가락번호 값을 계속사용할지 여부
        tmpObj.systemTextColor = 'FF58B7FF' // 강조 및 포인트 글자 색상 (기본값 : FF58B7FF)
        tmpObj.systemButtonColor = 'FF0971E8' // 버튼 색상 (기본값 : FF0971E8)
        // 검증 파라메타
        tmpObj1.captureMode = 'verify' // 검증
        tmpObj1.repeatCnt = '0' // 반복횟수
        tmpObj1.serviceCode = '등록' // 등록/인증/서명/일괄
        tmpObj1.serviceId = lv_Vm.lv_RouteParams.svcId // 영수증ID or 고객ID
        tmpObj1.userType = '피보험자' // 피보험자/계약자/수익자/보호자/기타
        tmpObj1.systemMessage = lv_Vm.lv_BaseData.custInfoCntnt // ex) 촬영 대상자 : 홍길동<br>최근 서명된 지문 : 2020년 05월 27일 왼손엄지
        tmpObj1.gender = gender // 성별
        tmpObj1.age = age // 나이
        tmpObj1.fingerMode = (lv_Vm.lv_BaseData.lastLivePstnCd.trim() === '') ? lv_Vm.lv_BaseData.bioDefaultFinger : lv_Vm.lv_BaseData.lastLivePstnCd // 손가락 번호 - 오른손엄지('1')~약지('5'), 왼손엄지('6')~약지('10')
        tmpObj1.useEnrolledPrivateInfo = 'true' // 연속촬영시 성별,나이,손가락번호 값을 계속사용할지 여부
        tmpObj1.systemTextColor = 'FF58B7FF' // 강조 및 포인트 글자 색상 (기본값 : FF58B7FF)
        tmpObj1.systemButtonColor = 'FF0971E8' // 버튼 색상 (기본값 : FF0971E8)
        // 1 - 전자서명, 2 - 지문등록, 3 - 전자서명검증
        if (this.lv_RouteParams.svcTp === '1' || this.lv_RouteParams.svcTp === '2') {
          args.captureOptions.push(tmpObj)
        } else if (this.lv_RouteParams.svcTp === '3') {
          args.captureOptions.push(tmpObj1)
        } else {
          // 지문촬영 연습 모드
          tmpObj.gender = 'male'
          tmpObj.age = '30'
          tmpObj.isExercise = 'false' // true: 솔루션 테스트모드로 동작 - 동작상 느려서 그냥 false로 고정해서 보냄
          args.captureOptions.push(tmpObj)
        }
        lv_Vm.lv_CaptureFingerPrintData = args
      }
      // 지문촬영앱 실행
      if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
        // 로컬환경일때 지문촬영앱에서 리턴되는 테스트 데이터 셋팅
        lv_Vm.lv_ResultBioData.push({
          state: 'success',
          captureMode: 'enroll',
          template: '590C6A45FAD1968474F52CF', // 암호화 된 템플릿 결과 값
          templateHash: '283BEC183AF6EB815BBB8BA1D911A39FB1812A83EE6D5A8', // 암호화 된 템플릿 해쉬 값
          secretKey: 'DFS9FA9SDFADFASD9AF9DSF9ASD999DDD9FDFDDXCVZXCVZXCDFEWDC', // 암호화 된 비공개 키
          privateInfo: {'age': '6', 'gender': 'male', 'verify_index_finger': 2} // 성별, 연령, 손가락번호 (솔루션에서 반환한 값)
        })
        lv_Vm.lv_ResultBioData.push({
          state: 'success',
          captureMode: 'verify',
          template: '590C6A45FAD1968474F52CF', // 암호화 된 템플릿 결과 값
          templateHash: '283BEC183AF6EB815BBB8BA1D911A39FB1812A83EE6D5A8', // 암호화 된 템플릿 해쉬 값
          secretKey: 'DFS9FA9SDFADFASD9AF9DSF9ASD999DDD9FDFDDXCVZXCVZXCDFEWDC', // 암호화 된 비공개 키
          privateInfo: {'age': '6', 'gender': 'male', 'verify_index_finger': 2} // 성별, 연령, 손가락번호 (솔루션에서 반환한 값)
        })
        lv_Vm.lv_CaptureFingerPrintFailedCnt = 0 // 촬영 실패건수 초기화
        lv_Vm.lv_PageType = '1'
      } else {
        // if (!lv_Vm.lv_IsProd) alert('Input Data : \n' + JSON.stringify(lv_Vm.lv_CaptureFingerPrintData))
        window.fdpbridge.exec('startCaptureFingerPrintPlugin', lv_Vm.lv_CaptureFingerPrintData, function (result) {
          let rst = result.data
          if (rst.state === 'success') {
            // 촬영 성공
            lv_Vm.lv_CaptureFingerPrintFailedCnt = 0 // 촬영 실패건수 초기화
            lv_Vm.lv_PageType = '1'
            lv_Vm.lv_ResultBioData = (rst.results && rst.results.length > 0) ? rst.results : []
          } else if (rst.state === 'failed') {
            // 촬영 실패
            lv_Vm.lv_TempText = '지문 촬영이 실패하였습니다.'
            lv_Vm.lv_TempText1 = '지문 촬영이 연속 실패하였습니다.'
            lv_Vm.lv_CaptureFingerPrintFailedCnt = lv_Vm.lv_CaptureFingerPrintFailedCnt + 1
            if (lv_Vm.lv_RouteParams.svcTp === '0') {
              lv_Vm.lv_TempText1 = '지문 촬영이 실패하였습니다.'
              lv_Vm.lv_PageType = '4' // 연습 모드 일때는 페이지 하단에 다시촬영/확인 버튼을 노출한다
            } else {
              if (lv_Vm.lv_CaptureFingerPrintFailedCnt < 2) {
                lv_Vm.lv_PageType = '2'
              } else {
                lv_Vm.lv_PageType = '4'
              }
            }
          } else if (rst.state === 'cancel') {
            // 촬영 취소
            lv_Vm.lv_TempText = '지문 촬영을 취소하였습니다.'
            lv_Vm.lv_TempText1 = '지문 촬영을 취소하였습니다.'
            if (lv_Vm.lv_RouteParams.svcTp === '0') {
              lv_Vm.lv_PageType = '4' // 연습 모드 일때는 페이지 하단에 다시촬영/확인 버튼을 노출한다
            } else {
              if (lv_Vm.lv_CaptureFingerPrintFailedCnt < 2) {
                lv_Vm.lv_PageType = '2'
              } else {
                lv_Vm.lv_PageType = '4'
              }
            }
          } else {
          }
        }, function (result) {
          if (result && result.data && result.data === 'No Support') {
            let msg = '미지원기기입니다. 플래쉬가 있는 스마트폰에서 시도해주세요.'
            lv_Vm.fn_BottomAlert(msg)
            return
          }

          lv_Vm.fn_BottomAlert(result)
        })
      }
      lv_Vm.getStore('progress').dispatch('UPDATE', false) // 프로그래스바 표시
    },
    /*********************************************************
     * Function명: fn_ReopenCaptureFingerPrint
     * 설명: 지문촬영앱 재실행
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_ReopenCaptureFingerPrint () {
      this.fn_OpenCaptureFingerPrint()
    },
    /*********************************************************
     * Function명: fn_FingerPrintRegister
     * 설명: 지문등록 및 인증
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_FingerPrintRegister () {
      this.getStore('progress').dispatch('UPDATE', true)
      if (this.lv_RouteParams.svcTp === '0') {
        this.fn_BackBtnClicked()
      } else {
        let lv_Vm = this
        let trnstId = 'txTSSPS41U2' // 지문등록, 인증, 전자서명분할저장 서비스 ID
        let pParams = {}
        pParams.bioComyCd = lv_Vm.lv_BaseData.bioComyCd // 지문인식기 인식업체코드금결원코드 (위닝아이=00204)
        pParams.bioScCd = lv_Vm.lv_BaseData.bioScCd // 생체인증구분코드(지문===001)
        pParams.bioSvcTp = lv_Vm.lv_RouteParams.svcTp // 서비스 구분 (1:전자서명, 2:지문등록, 3:전자서명검증)
        pParams.bioSvcId = lv_Vm.lv_RouteParams.svcId // svcTp에 따른 구분아이디  (영수증아이디, 고객아이디)
        pParams.liveAthntCustScCd = '02' // 고객구분 ('02' : 피보험자)
        pParams.vuhdInfo = lv_Vm.lv_BaseData.vuhdInfo // 계약정보
        pParams.custInfo = lv_Vm.lv_BaseData.custInfo // 고객정보
        pParams.listTemplet = [] // 지문촬영 결과값(솔루션에서 반환)
        lv_Vm.lv_ResultBioData.forEach(
          (item) => {
            let tmpObj = {}
            tmpObj.state = item.state || ''
            tmpObj.captureMode = item.captureMode || ''
            tmpObj.template = item.template || ''
            tmpObj.templateHash = item.templateHash || ''
            tmpObj.secretKey = item.secretKey || ''
            tmpObj.livePstnCd = item.privateInfo.verify_index_finger || ''
            pParams.listTemplet.push(tmpObj)
          }
        )
        // for (let i = 0; i < pParams.listTemplet.length; i ++) {
        //   this.fn_BottomAlert(i + ': ' + JSON.stringify(pParams.listTemplet[i]))
        // }
        lv_Vm.fn_ShowLoading(true) // 로딩 show
        this.post(lv_Vm, pParams, trnstId, 'S', {}, true)
          .then(function (res) {
            let resData = res.body || {}
            if (Object.keys(resData).length > 0 && resData.isProcOk && resData.isProcOk === 'Y') {
              // 등록, 인증, 분할 성공시
              lv_Vm.fn_ShowLoading(false)
              lv_Vm.fn_BackBtnClicked()
            } else {
              // 등록 및 인증에 실패시
              lv_Vm.lv_PageType = '3'
              lv_Vm.fn_ShowLoading(false)
              // lv_Vm.fn_BottomAlert(res.msgComm.msgDesc)
            }
            lv_Vm.getStore('progress').dispatch('UPDATE', false)
          })
          .catch(function (error) {
            window.vue.error(error)
            // lv_Vm.fn_BottomAlert(error)
            lv_Vm.fn_ShowLoading(false)
          })
      }
    },
    /*********************************************************
     * Function명: fn_CloseProcess
     * 설명: 지점처리 메시지팝업 show
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_CloseProcess () {
      this.$refs.bs1.open()
      this.lv_MsgAlert1 = '지문 촬영을 종료 하시겠습니까?'
      this.lv_ShowAlert1 = true
    },
    /*********************************************************
     * Function명: fn_ShowLoading
     * 설명: 사용자 프로그래스 로딩 컨트롤
     * Params: show(Boolean) - true: 로딩show, false: 로딩hide
     * Return: N/A
     *********************************************************/
    fn_ShowLoading (show) {
      if (show) {
        if (this.lv_Rolling) {
          this.lv_LoadingText1 = true
          this.fn_StartTimer()
        } else {
          this.lv_LoadingText0 = true
        }
        this.lv_IsShowLoading = true
      } else {
        this.lv_IsShowLoading = false
        if (this.lv_Rolling) {
          this.fn_EndTimer()
          this.lv_LoadingText1 = this.lv_LoadingText2 = this.lv_LoadingText3 = false
        } else {
          this.lv_LoadingText0 = false
        }
      }
    },
    /*********************************************************
     * Function명: fn_StartTimer
     * 설명: 타이머 시작
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_StartTimer () {
      let lv_Vm = this
      lv_Vm.lv_Timer = setInterval(() => {
        if (lv_Vm.lv_LoadingText1) {
          lv_Vm.lv_LoadingText1 = false
          lv_Vm.lv_LoadingText2 = true
        } else if (lv_Vm.lv_LoadingText2) {
          lv_Vm.lv_LoadingText2 = false
          lv_Vm.lv_LoadingText3 = true
        } else if (lv_Vm.lv_LoadingText3) {
          lv_Vm.lv_LoadingText3 = false
          lv_Vm.lv_LoadingText1 = true
        }
      }, 2000)
    },
    /*********************************************************
     * Function명: fn_EndTimer
     * 설명: 타이머 종료
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_EndTimer () {
      clearInterval(this.lv_Timer)
    },
    /*********************************************************
     * Function명: fn_BackBtnClicked
     * 설명: 하드웨어 백키가 선택 시
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_BackBtnClicked () {
      this.getStore('confirm').getters.getState.isShow
      this.getStore('confirm').dispatch('REMOVE')
      this.$router.go(-1)
    },
      showSnackbar() {
        this.$refs.snackbar.show({text:'고객님의 지문정보를 등록하고 있습니다. 잠시만 기다려주세요.'});
      },
      open() { this.$refs.bs1.open(); },
      close() { this.$refs.bs1.close(); },
    /*********************************************************
     * Function명: fn_BottomAlert
     * 설명: 알림창 팝업 호출
     * Params: N/A
     * Return: N/A
     *********************************************************/
   fn_BottomAlert (contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         single: true,
         title: '알림',
         content: contents
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
         }
       }
     })
   },
    }
  };
</script>
<style scoped>
</style>